$primary-color: #006d9e;
$primary-color-light: #006d9e80;
$secondary-color: #9e3402;

$success-color: rgb(1, 172, 1);
$error-color: rgb(191, 0, 0);
$warning-color: rgb(200, 77, 118);
$info-color: #999;

$rating-color: #ffa412;

$background-color-0: #f6f8fe;
$background-color-1: #002c77;
$background-color-2: #f5f8ff;
$background-color-3: #202970;
$background-color-4: #e8eefb;
$background-color-5: #e0e3f0;
$background-color-6: #2e3872;
$background-color-birthday: #34aadc1a;
$background-color-anniversary: #5856d61a;

$text-color-1: #151515;
$text-color-2: #abadc0;
$text-color-3: #ff3838;
$text-color-anniversary: #5856d6;

$white-color: #fff;

$border-color-1: #edf0ff;
$border-color-2: #cbcfe0;

$border-0: solid 1px transparent;
$border-1: solid 1px #edf0ff;
$border-2: solid 1px #cbcfe0;
$border-3: solid 1px rgba(237, 240, 255, 0.06);

$platinum-badge-color: #34aadc;
$gold-badge-color: #ffcb4a;
$silver-badge-color: #fff;
$bronze-badge-color: #cd7f32;

$counter-background-color: #029ec1;

$box-shadow-1: 0px 4px 5px 1px #00000036;
$box-shadow-2: 0px 8px 8px rgba(0, 0, 0, 0.04);
$box-shadow-3: 0px 4px 10px rgba(0, 0, 0, 0.15);
$primary-color: #006d9e;
$secondary-color: #9e3402;

$success-color: rgb(1, 172, 1);
$error-color: rgb(191, 0, 0);
$warning-color: rgb(200, 77, 118);
$info-color: #999;

$rating-color: #ffa412;

$background-color-0: #f6f8fe;
$background-color-1: #002c77;
$background-color-2: #f5f8ff;
$background-color-3: #202970;
$background-color-4: #e8eefb;
$background-color-5: #e0e3f0;
$background-color-birthday: #34aadc1a;
$background-color-anniversary: #5856d61a;

$text-color-1: #151515;
$text-color-2: #abadc0;
$text-color-3: #ff3838;
$text-color-anniversary: #5856d6;

$border-color-1: #edf0ff;
$border-1: solid 1px #edf0ff;
$border-2: solid 1px #cbcfe0;

$platinum-badge-color: #34aadc;
$gold-badge-color: #ffcb4a;
$silver-badge-color: #7c7c7c;
$bronze-badge-color: #cd7f32;

$white-color: #fff;
$counter-background-color : #029EC1;
